<template>
  <div @click="hideAllDropdown">
    <BreadCrumb title="Bookmark Page" subtitle="Case Law Summarizer"></BreadCrumb>
    <div class="d-flex align-items-center left mt-3" @click="goBack">
        <span class="material-symbols-rounded me-2">arrow_back</span>
        <p class="mb-0">Back</p>
      </div>

    <TableList
      class="table-margin"
      v-if="tableLoading || tableData.length > 0 || $route.query.client_case"
      :unbookmark-all="true"
      :delete-all="true"
      :load-data="loadData"
      :loading="tableLoading"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actions="tableHandler"
      @sort-data="sortData"
      :search="tableSearchConfig"
      @send-query="sendQuery"
    />

    <div v-else class="empty-placeholder">
      <span class="min-margin"></span>
      <img src="@/assets/misc/empty.svg" alt="No Content"/>
      <p>Welcome to the Case Law Summarizer! No trials to display yet—start a new one to get started.</p>
    </div>

    <!-- Delete Modal -->
    <b-modal
      id="delete-modal"
      hide-header
      hide-footer
      centered
    >
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p style="text-align: center;">
          <b>Are you sure you want to delete this campaign?</b><br>
          This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal')">
          Cancel
        </button>
        <button class="btn-danger" @click="deleteCampaign">Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BreadCrumb from '../../components/misc/BreadCrumb.vue';
import TableList from '../../components/misc/TableList.vue';
import general from '../../store/general';

export default {
  components: {
    BreadCrumb, 
    TableList,
  },

  data() { return {
    // header
    creds: {},

    // Case Flow Results
    tableLoading: true,
    tableSearchConfig: {
      title: "Bookmarked Campaigns: Case Law Summarizer",
    },
    tableConfig: {
      format: [
        {
          header: "ID",
          width: 9,
        },
        {
          header: "Campaign Info",
          width: 49,
        },
        {
          header: "Created By",
          width: 16,
          center: true,
        },
        {
          header: "Date",
          width: 16,
        },
        {
          header: "Action",
          width: 10,
          center: true,
        },
      ],
      options: [
        {
          icon: "cancel",
          label: "Undo Bookmark",
        },
        // {
        //   icon: "archive",
        //   label: "Archive",
        // },
        {
          icon: "delete",
          label: "Delete Campaign",
        },
      ],
      pagination: {
        current: 1,
        total: 0,
      },
    },
    fullData: [],
    tableData: [],

    // Dropdown Menu
    selected: null,
  }},

  watch: {
    // update route when changing page
    tableConfig: {
      handler(newCf) {
        const page = newCf.pagination.current;
        if (this.$route.query.page == page) return;

        const query = { page };
        if (this.$route.query.client_case) query.client_case = this.$route.query.client_case;
        this.$router.push({ query });
      },
      deep: true,
    },

    // update data when changed, works when using backward navigation
    $route() { this.loadData(); }
  },

  methods: {
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },

    sortData(data) { this.tableData = data; },

    tableHandler(o, id) {
      this.selected = this.tableData[id][0];
      const campaignId = this.tableData[id][0];

      switch(o) {
        // View File
        // case 0: 
        //   this.viewCampaign(campaignId);
        //   break;

         case 0:
          this.unbookmarkCampaign(campaignId); 
          break;

        // Archive
        // case 1:
        //   this.archiveCampaign();
        //   break;

        // Delete
          case 1: 
          this.$bvModal.show("delete-modal"); 
          break;
      }
    },
    viewCampaign(campaignId) {
      this.$router.push({
        name: "LegalCopilot_CaseFlowResult",
        params: { id: campaignId }
      });
    },
    unbookmarkCampaign(campaignId){
    general
        .UnbookmarkCampaign(campaignId)
        .then(res => {
            if (res.data.message === "Successful") this.$toast.success(res.data.message);
            this.loadData();
        });
    },
    archiveCampaign() {
      // console.log("Archive Campaign");
      // console.log(this.selected);
      general
        .ArchiveCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },

    deleteCampaign() {
      this.$bvModal.hide("delete-modal");
      general
        .DeleteCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          console.log("Success Delete");
          this.loadData();
        });
    },

    loadData() {
      const client_case = this.$route.query.client_case ?? null;
      const page = this.$route.query.page;

      const obj = { type: "case_summarizer", page };
      if (client_case) obj.client_case = client_case;

      this.tableLoading = true;
      general
        .GetBookmarkedCampaigns(obj)
        .then(res => {
          if (!res) return;
          const DATA = res.data.data;

          this.tableConfig.pagination = {
            current: DATA.current_page,
            total: DATA.total,
            perPage: DATA.per_page,
          };
          this.fullData = DATA.data;
          this.statLoading = false;
          this.tableLoading = false;

          this.tableData = this.fullData.map(ent => [
            ent.id,
            ent.file_name,
            this.$options.filters.username(ent.creator.name), 
            ent.created_at,
          ]);
        })
    },

    sendQuery(q) {
      const Rquery = this.$route.query;
      const query = { page: 1 };
      if (q) query.client_case = q;
      else if (Rquery.page > 1 || Rquery.client_case) this.$router.push({ query });
    },
    goBack() {
      this.$router.push({ name: 'Bookmark' });
    },
    navigate(to) {
      if (to === "+") {
        this.$router.push({ name: "LegalCopilot_PreTrial" });
      } else {
        return;
      }
    },
  },

  created() {
    const CRED = JSON.parse(localStorage.getItem("userInfo")).nexlaw_credits;
    this.creds.les = CRED.full.les - CRED.used.les;

    if (!this.$route.query.page) this.$router.push({ query: { page: 1 } });
    else this.loadData();
  },
}
</script>

<style scoped>
.table-margin {
  margin: 16px 10px 0px 10px;
}

.btn-primary {
  height: 40px;

  span {
    margin-right: 10px;
  }
}

.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 110px;
  }
  
  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
.left{
    color: var(--primary);
    cursor: pointer;
}
#Header{
    background: #fff;
}
</style>